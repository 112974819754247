import React, { Component } from 'react';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import {
  connectHits,
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
  ClearRefinements,
  RefinementList,
  Configure,
  connectStateResults,
} from 'react-instantsearch-dom';

import { latLngBounds } from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';

// import instantsearch from 'instantsearch.js';
import 'rheostat/initialize';
import 'rheostat/css/rheostat.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import PropTypes from 'prop-types';
import './App.css';
import accueilVeloPng from './accueil-velo.png';

// const MEILISEARCH_HOST = 'https://meilisearchlav.eu.ngrok.io';
const MEILISEARCH_HOST = 'https://loire-a-velo-search.apsulis.ninja';
const MEILISEARCH_PUBLIC_API_KEY = '4e4e2e1221314ee35864a3e996cdd9fe38289a8560a7b4cd2df94d0f2ffdf693';

const searchClient = instantMeiliSearch(
  MEILISEARCH_HOST,
  MEILISEARCH_PUBLIC_API_KEY
);

function SetBounds({ markers }) {
  const map = useMap();
  if (markers.length && markers.length > 0) {
    const markerBounds = latLngBounds([]);
    markers.forEach(marker => {
      markerBounds.extend([marker.coordonnees.lat, marker.coordonnees.lon]);
    });
    map.fitBounds(markerBounds.pad(0.5));
  }
  return null;
}

class MapHits extends Component {
  static defaultProps = {
    width: '425',
    height: '350',
  };

  renderMarker = hit => {
    const { hits } = this.props;
    if (!hits || hits.length === 0) {
      return null;
    }

    return hits.map((hit, index) => {
      const position = [hit.coordonnees.lat, hit.coordonnees.lon];
      return (
        <Marker position={position} key={index}>
          <Popup>
            <div className="row">
              <div className="column-left">
                <h3>

                <img src={accueilVeloPng} alt="Accueil vélo" />
                </h3>
              </div>
              <div className="column-right">
                <h3>{hit.title}</h3>
              </div>
            </div>
            <p>
              <a
                href={hit.url}
                target="_blank"
                rel="noopener noreferrer"
              >{`En savoir plus`}</a>
            </p>
          </Popup>
        </Marker>
      );
    });
  };

  render() {
    const { height, hits } = this.props;

    return (
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        maxZoom={20}
        style={{ height: `${height}px` }}
      >
        <TileLayer
          attribution="donn&eacute;es &copy; <a href='//osm.org/copyright'>OpenStreetMap</a>/ODbL - rendu <a href='//openstreetmap.fr'>OSM France</a>"
          url="https://cache-osm.ffr.fr/osmfr/{z}/{x}/{y}.png"
        />
        {this.renderMarker()}
        <SetBounds markers={hits} />
      </MapContainer>
    );
  }
}

const ConnectedMapHits = connectHits(MapHits);

class App extends Component {
  render() {
    return (
      <div>
        <header className="header">
          <h1 className="header-title">
            <a href="/">La Loire à vélo</a>
          </h1>
          {/* <p className="header-subtitle">
            nouveau moteur de recherche
            <a href="https://github.com/algolia/react-instantsearch">
              React InstantSearch
            </a>
          </p> */}
        </header>

        <div className="container">
          <InstantSearch searchClient={searchClient} indexName="Loire-a-velo">
            <div className="search-panel">
              <div className="search-panel__results">
                <div className="left-panel">
                  <ClearRefinements
                    translations={{
                      reset: 'Réinitialiser les filtres',
                    }}
                  />
                  <h2>Communes</h2>
                  <RefinementList
                    attribute="commune"
                    limit={4}
                    showMore={true}
                    translations={{
                      showMore(expanded) {
                        return expanded ? 'Voir moins' : 'Voir plus';
                      },
                    }}
                  />
                  <h2>Catégories</h2>
                  <RefinementList
                    attribute="category"
                    limit={4}
                    showMore={true}
                    translations={{
                      showMore(expanded) {
                        return expanded ? 'Voir moins' : 'Voir plus';
                      },
                    }}
                  />
                  <h2>Activités</h2>
                  <RefinementList
                    attribute="activite"
                    limit={20}
                    showMore={false}
                    operator={'and'}
                  />
                  <h2>Tags</h2>
                  <RefinementList
                    attribute="post_tag"
                    limit={20}
                    showMore={false}
                  // operator={'and'}
                  />
                  <Configure hitsPerPage={40} />
                </div>
                <div className="right-panel">
                  <NumberOfHits />
                  <div className="pagination">
                    <Pagination />
                  </div>
                  <SearchBox
                    className="searchbox"
                    translations={{
                      placeholder: 'Saisissez votre recherche',
                    }}
                  />
                  <ConnectedMapHits />
                  <Hits hitComponent={Hit} />
                </div>
              </div>
            </div>
          </InstantSearch>
        </div>
      </div>
    );
  }
}

function Hit(props) {
  // console.log('activite', props.hit.activite);
  const photoUrl = props.hit.featured_media.src;
  let listeCommunes = [];
  if (props.hit.commune) {
    listeCommunes = props.hit.commune.map(commune => (
      <li className="commune" key={commune}>
        {commune}
      </li>
    ));
  }
  let categories = [];
  if (props.hit.category) {
    categories = props.hit.category.map(category => (
      <li key={category}>{category}</li>
    ));
  }
  let activites = [];
  if (props.hit.activite) {
    activites = props.hit.activite
      .filter(activite => typeof activite === 'string')
      .map(activite => <li key={activite}>{activite}</li>);
  }
  return (
    <article>
      <a href={photoUrl}>
        <img className="photo" src={photoUrl} alt="Zut !" />
      </a>
      {/* {props.hit.coordonnees.lat}/{props.hit.coordonnees.lon} */}
      <h4>
        <Highlight attribute="title" hit={props.hit} />
      </h4>
      <div className="row">
        <div className="column-left">
          <img src={accueilVeloPng} alt="Accueil vélo" />
        </div>
        <div className="column-right">
          <ul className="commune">{listeCommunes}</ul>
        </div>
      </div>
      <h5>Catégories</h5>
      <ul className="category">{categories}</ul>
      <h5>Activites</h5>
      <ul className="activite">{activites}</ul>
      {/* <p>
        <Highlight attribute="content" hit={props.hit} />
      </p> */}
    </article>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

NumberHits.propTypes = {
  searchResults: PropTypes.object,
};

function NumberHits({ searchResults }) {
  // const hasResults = searchResults && searchResults.nbHits !== 0;
  const nbHits = searchResults && searchResults.nbHits;
  return <h2>Les offres : {nbHits}</h2>;
}
const NumberOfHits = connectStateResults(NumberHits);

export default App;
